import { useTranslation } from 'next-i18next';
import React from 'react';
import classNames from 'classnames';
import { Tariff } from '@web/shop-logic/dist/hooks/types';
import { Button } from '@/components/atoms/Button';

const buttonClasses = 'rounded-full max-h-4 w-4 px-0';
const signClasses = 'text-h3 font-normal pb-[3px]';

interface CounterProps extends React.HTMLProps<HTMLDivElement> {
  tariff: string;
  minValue: number;
  maxValue: number;
  onCounterChange: (tariffs: Tariff[]) => void;
  value: number;
  ariaTranslationIncrease: string;
  ariaTranslationDecrease: string;
  disabled?: boolean;
}

const Counter: React.FC<CounterProps> = ({
  minValue,
  maxValue,
  onCounterChange,
  tariff,
  value,
  className,
  ariaTranslationIncrease,
  ariaTranslationDecrease,
  disabled,
}) => {
  const { t } = useTranslation();

  const decreaseDisabled = value <= minValue;
  const increaseDisabled = value >= maxValue;

  const increaseCount = () => onCounterChange(new Array(value + 1).fill(tariff));
  const decreaseCount = () => onCounterChange(new Array(value - 1).fill(tariff));

  return (
    <div className={className}>
      <Button
        className={classNames(buttonClasses, !decreaseDisabled && 'border-2')}
        disabled={decreaseDisabled || disabled}
        variant="secondary"
        aria-label={t(ariaTranslationDecrease, { count: value })}
        onClick={decreaseCount}
      >
        <span className={signClasses}>-</span>
      </Button>
      <span className="inline-block w-3 text-center" aria-hidden>
        {value}
      </span>
      <Button
        className={classNames(buttonClasses, !increaseDisabled && 'border-2')}
        disabled={increaseDisabled || disabled}
        variant="secondary"
        aria-label={t(ariaTranslationIncrease, { count: value })}
        onClick={increaseCount}
      >
        <span className={signClasses}>+</span>
      </Button>
    </div>
  );
};

export default Counter;
