// eslint-disable-next-line import/prefer-default-export
export function groupBy<K extends string | number | boolean, V>(
  list: Array<V>,
  keyGetter: K | ((input: V) => K),
): Map<K, Array<V>> {
  const map = new Map<K, Array<V>>();
  (list || []).forEach((item) => {
    const key = typeof keyGetter !== 'function' ? keyGetter : keyGetter(item);
    const collection = map.get(key);
    if (!collection) map.set(key, [item]);
    else collection.push(item);
  });
  return map;
}
