import Chevron from '../atoms/Chevron';
import React, { FC } from 'react';

interface Props {
  isOpened: boolean;
  onClick: () => void;
  count: number;
}

const SectionTitle: FC<Props> = ({ count, children, isOpened, onClick }) => (
  <div
    onClick={onClick}
    className="px-2 py-1 bg-neutral-graylight text-primary-blue flex flex-row items-center cursor-pointer justify-between"
  >
    <div className="flex flex-row items-center font-bold">
      {children}
      <Chevron isOpened={isOpened} />
    </div>
    {!!count && (
      <div className="text-12 w-3 h-3 text-primary-blue bg-primary-bluelight flex items-center justify-center rounded-full">
        {count}
      </div>
    )}
  </div>
);

export default SectionTitle;
