import { Trans, useTranslation } from 'next-i18next';
import React from 'react';
import { Button } from '@/components/atoms/Button';
import Card from '@/components/atoms/Card';

interface TariffSelectAfterLoginModalProps {
  userTarif: string;
  searchTarif: string;
  handleUserTarif: () => void;
  handleSearchTarif: () => void;
}

const TariffSelectAfterLoginModal: React.FC<TariffSelectAfterLoginModalProps> = ({
  userTarif,
  searchTarif,
  handleUserTarif,
  handleSearchTarif,
}) => {
  const { t } = useTranslation();

  return (
    <Card className="m-auto">
      <h2 className="h2 mb-2">
        <Trans i18nKey="tariffSelectAfterLoginModal.header" />
      </h2>

      {/**
       * Tarif může obsahovat znak `<`, který se v Nextu parsuje jako začátek HTML
       * Tarif musí být v této chvíli escapovaný funkcí `escapeHtmlBrackets()`
       */}
      <p
        dangerouslySetInnerHTML={{
          __html: t('tariffSelectAfterLoginModal.text', {
            userTarif,
            searchTarif,
          }),
        }}
      />
      <div className="flex sm:flex-col justify-between gap-2 mt-4">
        <Button
          size="big"
          variant="secondary"
          onClick={handleSearchTarif}
          gtmPlace="TariffSelectAfterLoginModal"
          gtmName={`Choose search tarif (${searchTarif})`}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: t('tariffSelectAfterLoginModal.handleSearchTarif', {
                searchTarif,
              }),
            }}
          />
        </Button>
        <Button
          size="big"
          onClick={handleUserTarif}
          gtmPlace="TariffSelectAfterLoginModal"
          gtmName={`Choose user tarif (${userTarif})`}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: t('tariffSelectAfterLoginModal.handleUserTarif', {
                userTarif,
              }),
            }}
          />
        </Button>
      </div>
    </Card>
  );
};

export default TariffSelectAfterLoginModal;
