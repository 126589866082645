import Tariff from '../registration/tariffOptions';
import Tooltip from './Tooltip';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { useTariffs } from '@web/shop-logic';
import { TariffResponse } from '@web/shop-logic/dist/hooks/useTariffs';
import { InfoLine } from '@/icons';
import { groupBy } from '@/utils/arrayUtils';

interface StringifiedTariffsProps {
  selectedTariffs: Tariff[];
  showZtpWarning?: boolean;
}

export const getStringifyTariffs = (tariffs: Tariff[], data: TariffResponse[]) => {
  const tariffGroups = groupBy(tariffs, (tariff) => tariff);
  return Array.from(tariffGroups.entries()).map(([k, v]) => ({
    tariff: k,
    title: `${v.length}× ${data.find((tr) => tr.key === k)?.value}`,
  }));
};

const StringifiedTariffs: React.FC<StringifiedTariffsProps> = ({
  selectedTariffs,
  showZtpWarning,
}) => {
  const { data = [] } = useTariffs();
  const { t } = useTranslation();

  const stringifyTariffs = getStringifyTariffs(selectedTariffs, data);
  return (
    <>
      {stringifyTariffs.map(({ tariff, title }) => (
        <li key={tariff} className="flex gap-1">
          {title}
          {tariff === Tariff.Disabled && showZtpWarning && (
            <Tooltip title={t('ztp.international.tooltip')}>
              <div>
                <InfoLine className="w-3 h-3 fill-neutral-gray" />
              </div>
            </Tooltip>
          )}
        </li>
      ))}
    </>
  );
};

export default StringifiedTariffs;
