import { TariffType, TariffTypeToJsx, TariffTypeToTariff } from '../organisms/TariffSelect';
import Tariff from '../registration/tariffOptions';
import SectionTitle from './SectionTitle';
import { Trans } from 'next-i18next';
import React, { Fragment, useState } from 'react';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { SearchQuery } from '@/components/templates/SearchBox';

interface PassengersProps {
  tariffs: Tariff[];
  renderTariff: (tariff: string, index: number, { length }: { length: number }) => JSX.Element;
  TariffsByType: TariffTypeToTariff;
  Icon: TariffTypeToJsx;
  Title: TariffTypeToJsx;
  BasicTariffs: Tariff[];
  NotRestTariffs: Tariff[];
}

const Passsengers: React.FC<PassengersProps> = ({
  renderTariff,
  tariffs,
  TariffsByType,
  Icon,
  Title,
  BasicTariffs,
  NotRestTariffs,
}) => {
  const { values } = useFormikContext<SearchQuery>();

  const initOpenSections = (selectedTariffs: Tariff[]) => ({
    [TariffType.Basic]: selectedTariffs.some((tariff) => BasicTariffs.includes(tariff)),
    [TariffType.Rest]: selectedTariffs.some((tariff) => NotRestTariffs.includes(tariff)),
  });

  const [open, setOpen] = useState<Record<TariffType, boolean>>(initOpenSections(tariffs));

  const selectedCount = values.tariffs.length;

  return (
    <>
      <div
        /* tabIndex aby se dal focus na element a nemusi byt nactene tarify */
        tabIndex={0}
        className="min-w-popper sm:min-w-[100vw] searchBox-mob-container sm:pb-[128px]"
      >
        {Object.values(TariffType).map((type) =>
          TariffsByType[type].length ? (
            <Fragment key={type}>
              <SectionTitle
                count={values.tariffs.reduce(
                  (prev, curr) => prev + (TariffsByType[type].includes(curr) ? 1 : 0),
                  0,
                )}
                isOpened={open[type]}
                onClick={() => setOpen({ ...open, [type]: !open[type] })}
              >
                {Icon[type]}
                {Title[type]}
              </SectionTitle>
              {open[type] && TariffsByType[type].map(renderTariff)}
            </Fragment>
          ) : null,
        )}
      </div>
      <div className="flex flex-row justify-between p-2 shadow-popper sm:fixed bg-neutral-white bottom-[4rem] w-full">
        <div className="mr-3">
          <Trans
            i18nKey="passengerSelect.selectedCount"
            components={{
              blueBold: (
                <span
                  className={classNames(
                    'font-bold',
                    selectedCount === 6 ? 'text-secondary-orangedark' : 'text-primary-blue',
                  )}
                />
              ),
            }}
            values={{ count: selectedCount }}
          />
        </div>
        <span className="text-neutral-gray">
          <Trans i18nKey="passengerSelect.maximumInfo" />
        </span>
      </div>
    </>
  );
};

export default Passsengers;
