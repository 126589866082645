import React, { FC } from 'react';

interface Props {
  count: number;
}

const TariffTitle: FC<Props> = ({ children, count }) => (
  <div className="px-2 py-1 bg-neutral-graylight text-primary-blue flex flex-row items-center cursor-pointer justify-between">
    <div className="flex flex-row items-center font-bold">{children}</div>
    {!!count && (
      <div className="text-12 w-3 h-3 text-primary-blue bg-primary-bluelight flex items-center justify-center rounded-full">
        {count}
      </div>
    )}
  </div>
);

export default TariffTitle;
