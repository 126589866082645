import React from 'react';
import classNames from 'classnames';

interface CardProps {
  title?: string;
  className?: string;
}

const Card: React.FC<CardProps> = ({ title, className, children }) => (
  <div
    className={classNames('flex-col rounded-sm border border-neutral-gray3 p-3 w-full', className)}
  >
    {title && <h2 className="h2 pb-2">{title}</h2>}
    {children}
  </div>
);

export default Card;
